<template>
  <div>
    <!--begin::customer-->
    <div>

      <h3 class="card-label">
        {{ $t('accounts_routing.accounts_routing') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="card card-custom">
        <div class="card-body">
          <b-tabs content-class="mt-3" pills card vertical>
            <b-tab v-for="(_data, index) in data" :title="_data.tab_name"  :key="'b-tab_'+index">
              <div v-for="(_li, _index_1) in _data.list" class="form-group row" :key="_li.key">
                <div class="col-lg-12 mb-5">
                  <h5>{{ _li.name }}</h5>
                  <hr>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.routing_type') }}</label>
                  <div class="input-group">
                    <select name="" v-model="_li.type_routing" @change="onChangeType(index, _li.key)" class="custom-select" :class="validation && validation.type_routing ? 'is-invalid' : ''">
                      <option v-for="row in types[0]" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.type_routing" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.type_routing[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.account') }}<span class="text-danger">*</span></label>
                  <multiselect v-if="_li.type_routing == 2"
                               v-model="_li.account"
                               @input="updateValue(index, _li.key, $event)"
                               :placeholder="$t('accounts_routing.account')"
                               label="label"
                               track-by="id"
                               :options="accounts_list"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getAccounts($event)">
                  </multiselect>
                  <treeselect v-else
                              :options="accounts_parents_list"
                              :load-options="loadOptions"
                              :multiple="false"
                              :value="_li.account_id"
                              @input="updateValueTree(index, _li.key, $event)"
                              :searchable="true"
                              :class="validation && validation.account_id ? 'is-invalid' : ''"
                              :show-count="true"
                              :no-children-text="$t('No_sub_options')"
                              :no-options-text="$t('No_options_available')"
                              :no-results-text="$t('No_results_found')"
                              :placeholder="$t('Select')">
                  </treeselect>

                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                    </span>
                </div>
              </div>
            </b-tab>

          </b-tabs>
        </div>
      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>

    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "form-accounts-routing",

  data() {
    return {
      // mainRoute: 'accounting/accounts-routing',
      mainRoute: 'accounting/routing-configurations',
      mainRouteOfStructureView: 'accounting/routing-configurations',
      mainRouteDependency: 'base/dependency',
      data: [],

      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,
      validation: null,

      accounts_list: [],
      accounts_parents_list: [],
      types: {},
      innerList: []
    };
  },

  methods: {

    save() {
      this.create();
    },

    create() {
      let _data = [];
      let that = this;
      that.data.forEach(function (row) {
        Object.keys(row.list).forEach(function (key) {
          _data.push({
                key: row.list[key].key,
                tab_key: row.list[key].tab_key,
                account_id: row.list[key].account_id,
                account: row.list[key].account,
                type_routing: row.list[key].type_routing,
          });
        });
      });

      ApiService.post(`${this.mainRoute}`, {
        ..._data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        Vue.prototype.$postStatus = true;
      }).catch((error) => {
        Vue.prototype.$postStatus = true;
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },


    async getStructureView() {
       await ApiService.get(this.mainRouteOfStructureView).then((response) => {
        this.data = [];
        let _tabs_keys = [];
        _tabs_keys = response.data.data.map((row) => {
          return row.tab_key;
        });
        _tabs_keys = [...new Set(_tabs_keys)];
        this.data = _tabs_keys.map((val)=>{
          let _tab_name = response.data.data.find((row) => row.tab_key == val);
          let _list = response.data.data.filter((row) => row.tab_key == val).map((row) => {
            return row;
          });
          let _final_list = {};
          _list.forEach(function(obj) {
            _final_list[obj.key] = obj;
          });
          return {
            tab_key: val,
            tab_name: _tab_name.tab_name,
            list: _final_list
          };
        })


         // this.accounts_list = [];
         this.data.forEach((row,index)=>{
           let _keys = Object.keys(row.list);
           _keys.forEach((key)=>{
             let _response_row = response.data.data.find((res) => res.key == key);
             if (_response_row){
               // if(_response_row.account){
               //   this.accounts_list.push(_response_row.account);
               // }

               this.data[index].list[key].account_id = _response_row.account_id;
               this.data[index].list[key].account = _response_row.account;
               this.data[index].list[key].type_routing = _response_row.type_routing;
             }
           })
         })

      });
    },
    // async getData() {
    //   ApiService.get(this.mainRoute).then((response) => {
    //     this.accounts_list = [];
    //     this.data.forEach((row,index)=>{
    //       let _keys = Object.keys(row.list);
    //       _keys.forEach((key)=>{
    //         let _response_row = response.data.data.find((res) => res.key == key);
    //         if (_response_row){
    //           this.accounts_list.push(_response_row.account);
    //           this.data[index].list[key].account_id = _response_row.account_id;
    //           this.data[index].list[key].account = _response_row.account;
    //           this.data[index].list[key].type_routing = _response_row.type_routing;
    //         }
    //       })
    //     })
    //   });
    // },

    getAccounts(filter) {
      if (filter && filter.length >= 3)
        return ApiService.get(this.mainRouteDependency + `/account_parents_leaf_nodes`, {params: {name: filter}}).then((response) => {
          this.accounts_list = response.data.data;
        });
    },
    getAccountParents() {
      ApiService.get(this.mainRouteDependency + `/account_parents`).then((response) => {
        this.accounts_parents_list = response.data.data;
      });
    },
    getTypes() {
      ApiService.get(this.mainRouteDependency + `/type_routing`).then((response) => {
        this.types = response.data.data;
      });
    },

    loadOptions() {
    },

    updateValue(index, key, value) {
      this.data[index].list[key].account_id = value ? value.id : null;
    },
    updateValueTree(index, key, value) {
      this.data[index].list[key].account_id = value;
      this.data[index].list[key].account = null;
    },
    onChangeType(index, key) {
      this.data[index].list[key].account_id = null;
      this.data[index].list[key].account = null;
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.accounts_routing")}]);

    this.getAccountParents();
    this.getTypes();
    this.getStructureView();
    // let promise = this.getStructureView();
    // Promise.all([promise]).then(()=>{
    //   this.getData();
    // })


  },
};
</script>